import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useChain, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { ReactComponent as LinkArrow } from '../images/icons/link-arrow.svg';
import { themeTransition } from '../theme/themeTransition';
import { Box, BoxProps } from './common/Box';
import { Grid } from './common/Grid';
import { Text } from './common/Text';

export interface ArticleTeaserProps {
  image?: any;
  imageAltText: string;
  kicker?: string;
  title?: string;
  link: string;
  index: number;
  eventDate: Date | null;
  bg?: string;
  mb?: number | number[];
  forceSquare?: boolean;
  animateInColumns?: true;
  gridColumn?: BoxProps['gridColumn'];
}

// TODO: fixa typsnitt på title, ska vara lite mindre, typ 40
export const ArticleTeaser: React.FC<ArticleTeaserProps> = props => {
  const [boundsRef, bounds] = useMeasure({ polyfill });
  const [ref, inView] = useInView({ triggerOnce: true });
  const imageSpring = useSpring({ opacity: inView ? 1 : 0.5 });
  const [active, setActive] = useState(true);

  useEffect(() => {
    setActive(false);
  }, [setActive]);

  const loaderRef = useRef<any>();
  const loaderSpring = useSpring({
    from: { transform: `scaleX(0)` },
    to: { transform: `scaleX(1)` },
    ref: loaderRef,
  });

  const imageOverlayRef = useRef<any>();
  const imageOverlaySpring = useSpring({
    from: { transform: `scaleX(1)` },
    to: { transform: `scaleX(0)` },
    ref: imageOverlayRef,
  });

  const textRef = useRef<any>();
  const textSpring = useSpring({
    from: { opacity: 0, transform: `translateX(-20px)` },
    to: { opacity: 1, transform: `translateX(0px)` },
    ref: textRef,
    onFrame: (t: any) => t.opacity > 0.8 && setActive(true),
  });

  useChain(inView ? [loaderRef, imageOverlayRef, textRef] : [], [0, 0.5, 0.7]);

  const url = props.link;
  // If no image is defined, the grid will always be one column
  const gridWidth = props.image ? ['1fr', '1fr 1fr'] : '1fr';

  return (
    <AnimatedBox
      // style={containerSpring} // TODO: Add it back?
      gridColumn={props.gridColumn || '1 / -1'}
      mb={props.mb ?? 3}
      zIndex={1}
      position="relative"
    >
      {/* {props.index === 2 && <Egg eggNumber={3} />} */}
      <StyledLink to={url} innerRef={ref} activeHover={active}>
        <InnerBox>
          <Grid gridTemplateColumns={gridWidth} ref={boundsRef}>
            <Box order={[1, props.index % 2 === 0 ? 1 : 2]} position="relative">
              <AnimatedBox style={imageSpring} /* TODO: Fix opacity*/>
                {props.image && (
                  <GatsbyImage
                    image={{ ...props.image, aspectRatio: 4 / 3 }}
                    alt={props.imageAltText}
                  />
                )}
              </AnimatedBox>
              <ImageOverlay style={imageOverlaySpring} bg={props.bg} />
            </Box>
            <InnerContent
              height={props.image ? 'auto' : ['auto', props.forceSquare ? bounds.width : 300]}
              py={[4, 3, 3, 48]}
              px={[4, 3, 3, 48]}
              order={[2, props.index % 2 === 0 ? 2 : 1]}
            >
              <AnimatedBox position="relative" style={textSpring}>
                <Kicker display="inline-flex" py={3}>
                  <Text fontSize={[1, 2]} fontWeight={300}>
                    {props.kicker}
                  </Text>
                </Kicker>
                <Text
                  fontSize={[22, 3, 3, 5]}
                  fontWeight={500}
                  lineHeight={1.3}
                  letterSpacing="-0.02em"
                  mb={[5, 5, 5, 0]}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  {props.title}
                </Text>
              </AnimatedBox>
              <Arrow>
                <LinkArrow style={{ maxWidth: '36px', maxHeight: '19px' }} />
              </Arrow>
            </InnerContent>
          </Grid>
        </InnerBox>
      </StyledLink>
      <LoaderBlock style={loaderSpring} bg={props.bg} />
    </AnimatedBox>
  );
};

const LoaderBlock = animated(styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform-origin: center left;
`);

const ImageOverlay = animated(styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform-origin: center right;
`);

const Arrow = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  ${themeTransition('transform')};
`;

const AnimatedBox = animated(Box);

const Kicker = styled(Box)`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    display: block;
    background-color: ${props => props.theme.colors.text};
    transform-origin: left center;
    ${themeTransition(['transform', 'background-color'])}
  }
`;

export const InnerBox = styled(Box)`
  background-color: ${props => props.bg || 'transparent'};
  z-index: 1;
  position: relative;

  ${themeTransition('background-color')}
`;

const InnerContent = styled(Box)`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: ${props => props.theme.colors.teaseHover};
    transition: transform 0.4s cubic-bezier(0.16, 0.97, 0.46, 0.95);
    transform: translateX(-107%);
  }
`;

const StyledLink = styled(Link)<{ activeHover: boolean }>`
  text-decoration: none;
  color: #000;

  &:hover {
    ${InnerContent}::before {
      ${props => (props.activeHover ? `transform: translateX(0%) rotate(0deg);` : '')}
    }

    ${Kicker}::before {
      ${props => (props.activeHover ? `transform: scale(3, 1);` : '')}
    }

    ${Arrow} {
      animation: pulse 1s infinite;
      ${props =>
        props.activeHover
          ? `@keyframes pulse {
        0% { transform: translateX(0); }
        50% { transform: translateX(1rem); }
        100% { transform: translateX(0); }
        }
      }`
          : ''}
    }
  }
`;
